.container {
	display: flex;
	margin-bottom: 8px;
}

.header {
	background-color: var(--table-active-filter-background);
	padding: 8px 8px 8px 16px;
	margin: 0 2px 0 0;
	border-radius: 16px 0 0 16px;
	font-size: var(--font-size-small);
}

.items {
	background-color: var(--table-active-filter-background);
	padding: 8px;
	min-width: 0;
}

.button {
	background-color: var(--table-active-filter-background);
	padding: 8px 16px 8px 8px;
	margin-left: 2px;
	border-radius: 0 16px 16px 0;
	cursor: pointer;

	svg {
		margin-bottom: 2px;
	}

	&:hover {
		color: var(--error-color);
	}
}
