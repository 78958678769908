@import '../../control.mixins.scss';

.container {
	display: flex;
	align-items: center;
    width: 100%; // for shorten

	.picker_container {
		position: relative;
		flex: 1;
		width: 100%; // for shorten
	}

	.description_container {
		margin: 0 0 0 8px;
	}
}

.picker {
	@include control();
	cursor: pointer;
	padding-right: 30px;
}

.arrow {
	position: absolute;
	right: 10px;
	top: 7px;
	pointer-events: none;
}

.item {
	display: flex;
	align-items: center;
	margin-bottom: 4px;

	.expander {
		width: 16px;
		height: 16px;
		padding: 0 4px;
		cursor: pointer;
	}

	.item_content {
		cursor: pointer;
		flex: 1 1;
		overflow: hidden;
		display: flex;
		align-items: center;
		padding: 2px 0 2px 4px;
		border-radius: calc(var(--border-radius) / 2);
	}

	&:hover {
		color: var(--tab-active-color);
	}

	.selected {
		color: var(--primary-color);
	}
}

.nested {
	margin-left: 16px;
}

.disabled {
	@include disabled_control();
}
