.container {
	display: flex;
	flex: 1;

	&.dragging_over > div:not(.dragging, :last-child) {
		background-color: var(--dragndrop-hover-background);
		border-radius: 6px;
	}
}

.dragging {
	color: var(--dragndrop-over-active-color);
	outline: 1px solid var(--dragndrop-border-color);
	border-radius: 6px;
}