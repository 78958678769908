.container {
    position: absolute;
    top: -6px;
    right: 5px;
	font-size: var(--font-size-small);
    color: var(--field-max-length-color);
    padding: 0 4px;
    line-height: 12px;
    background: var(--field-max-length-background);
    border-radius: 3px;
}
