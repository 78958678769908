.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.message {
	font-size: var(--font-size-xlarge);
	font-weight: bold;
}
