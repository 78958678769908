.container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1; // because of frozen columns in CustomTable
}

.background {
	background: var(--with-fetch-background);
}
