@import '../control.mixins.scss';

.wrapper {
	display: flex;
	align-items: center;

	&.small {
		height: 100%;
	}
}

.container {
	flex: 1;
	position: relative;
}

.inputNumber {
	@include control();

	&:focus ~ .max_length {
		display: block;
	}
}

.max_length {
	display: none;
}

.explanation {
	@include explanation();
}

.align_right {
	text-align: right;
}

.align_center {
	text-align: center;
}
