.item {
	font-size: var(--font-size-small);
}

// AND/OR
.conjunction  {
	color: var(--neutral-color);
}

// EQUALS...
.operation {
	color: var(--brand-color);
}

.delete_button {
	cursor: pointer;
	padding: 4px;
	border: solid #ccc 1px;
	border-radius: 4px;
	margin-left: 4px;
	display: inline-block;
}
