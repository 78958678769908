@import './Panel/colorPickerPanel.module.scss';

.color_picker_wrapper {
	position: relative;
	display: inline-block;
}

.color_picker_disabled {
	opacity: 0.7;
	pointer-events: none;
}
