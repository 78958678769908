@font-face {
  font-family: 'text_editor';
  src:
    url('fonts/text_editor.ttf?2mdvjl') format('truetype'),
    url('fonts/text_editor.woff?2mdvjl') format('woff'),
    url('fonts/text_editor.svg?2mdvjl#text_editor') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="text_editor_"], [class*=" text_editor_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'text_editor' !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text_editor_bold:before {
  content: "\e900";
}
.text_editor_italic:before {
  content: "\e907";
}
.text_editor_underline:before {
  content: "\e90e";
}
.text_editor_strikethrought:before {
  content: "\e90b";
}
.text_editor_superscript:before {
  content: "\e90d";
}
.text_editor_subscript:before {
  content: "\e90c";
}
.text_editor_alignLeft:before {
  content: "\e901";
}
.text_editor_alignMiddle:before {
  content: "\e903";
}
.text_editor_alignRight:before {
  content: "\e902";
}
.text_editor_justify:before {
  content: "\e908";
}
.text_editor_indent:before {
  content: "\e906";
}
.text_editor_outdent:before {
  content: "\e90a";
}
.text_editor_ordered:before {
  content: "\e909";
}
.text_editor_unordered:before {
  content: "\e90f";
}
.text_editor_emoji:before {
  content: "\e904";
}
.text_editor_image:before {
  content: "\e905";
}
.text_editor_link:before {
  content: "\e910";
}
