.Toastify__toast {
	border-radius: 4px !important;
}

.Toastify__toast--success {
	background: #2F9E36;
}

.Toastify__toast--error {
	background: #9E332F;
}

.Toastify__toast--info {
	background: #858585;
}

.Toastify__close-button {
	visibility: hidden;
}
