@import '../control.mixins.scss';

.wrapper {
	display: flex;
    align-items: center;
	column-gap: 4px;
	padding: 6px 0;
}

.semaphore_item {
	@include control();
	height: 20px;
	width: 20px;
	padding: 0;
	border-radius: 50%;
	cursor: pointer;
}

.disabled {
	@include disabled_control();
	pointer-events: none; /* prevent click when is disabled */

	&.semaphore_item.selected {
		opacity: 0.7;
	}
}

.explanation {
	@include explanation();
}

.explanation_item {
	display: flex;
	min-width: 260px;

	&:not(:last-child) {
		margin-bottom: 6px;
	}

	.circle {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin: 3px 6px 0 0;
		flex-shrink: 0;
	}
}

.tooltip {
	position: fixed;
	background-color: var(--table-tooltip-background);
	color: var(--content-color);
	font-size: var(--font-size-small);
	padding: 6px;
	border-radius: 4px;
	z-index: 130; // bigger than Dialog's 120
	max-width: 375px;
	word-wrap: break-word;
	white-space: normal;
	transform: translateX(-50%);

	.arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 6px solid var(--table-tooltip-background);
		bottom: -4px;
		left: 50%;
		transform: translateX(-50%);
	}
}