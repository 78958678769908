.container {
    display: flex;
    flex-direction: column;
	overflow-x: auto;

	position: relative; // because of frozen separators and resize handlers
    font-size: var(--font-size-table);
}

.empty_table {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: calc(5 * 32px);
	font-size: var(--font-size-xlarge);
}