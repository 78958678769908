.container {

	.row + .row {
		margin-top: 8px;
	}

	.cell + .cell {
		margin-left: 16px;
	}
}

.container_draggable {
	.row {
		margin-bottom: 8px;
	}

	.cell {
		margin-left: 16px;
	}
}
