.container {

	.item {
		position: relative; // delete is absolute
		padding: 0 0 32px 0;
	}

	.item + .item {
		padding: 32px 0;
		border-top: 1px solid var(--content-separator-background);
	}
}

.delete {
	position: absolute;
	top: 8px;
	right: 0;
}
