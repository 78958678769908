.container {
	display: flex;
	justify-content: flex-end;

	.item:not(:first-child) {
		margin-left: 4px;
	}
}

.item {
	display: flex;
    align-self: center;
	margin-top: 16px;
	padding: 0 4px;
	cursor: pointer;

	svg {
		fill: var(--content-color);
	}
}

.disabled {
	cursor: not-allowed;

	svg {
		fill: var(--paging-disabled-color);
	}
}
