.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--neutral-color);
	width: 32px;
	height: 32px;
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background-color: #515151;
	}
}