.container {
	line-height: 16px;
}

.dropdown_content {
	position: absolute;
	background-color: #373737;
	border-radius: 4px;
	box-shadow: 0 5px 8px #0000002B;
	z-index: 130; // bigger than Dialog's 120

	.link_item {
		position: relative; // because of spinner
		display: flex;
		align-items: center;
		padding: 0 8px;
		height: 32px;
		cursor: pointer;

		.link {
			flex: 1;
			// shorten
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&:hover {
			background-color: #4a4a4a;

			&:first-child {
				border-radius: 4px 4px 0 0;
			}

			&:last-child {
				border-radius: 0 0 4px 4px;
			}

			&:only-child {
				border-radius: 4px;
			}
		}
	}
}

.link_badge {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--content-background);
	color: var(--content-color);
	position: absolute;
	bottom: 2px;
	left: 13px;
	border-radius: 50%;
	width: 14px;
	height: 14px;
	font-size: 10px;
}

.simple_attachment {
	display: flex;
	align-items: center;

	.icon {
		margin-right: 6px;
	}
}
