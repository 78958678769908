@import '../control.mixins.scss';

.container {
	display: flex;
	align-items: center;

	.select_container {
		position: relative;
		flex: 1;
	}

	.description_container {
		margin: 0 0 0 8px;
	}

	&.small {
		height: 100%;
	}
}

.select {
	@include control();
	padding: 6px 30px 6px 10px !important;

	&:enabled {
		cursor: pointer;
	}

	option {
		color: var(--field-color);
	}

	&.small {
		padding: 4px 26px 4px 6px !important;
	}
}

.arrow {
	position: absolute;
	right: 10px;
	top: 7px;
	pointer-events: none;

	&.small {
		top: 4px
	}
}

.placeholder {
	color: var(--field-placeholder-color);
}
