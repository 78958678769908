@import '../control.mixins.scss';

.container {
	min-height: 32px; // changed from height because of long labelBefore text
	display: flex;
	align-items: center;

	&.small {
		min-height: 24px;
	}
}

.label {
	cursor: pointer;
	line-height: 16px;
	user-select: none;
	position: relative;

	&::before {
		content: '';
		border: $control-border;
		background: var(--checkbox-unchecked-background);
		border-radius: 4px;
		width: 16px;
		height: 16px;
		position: absolute;
		transition-property: background, border, border-color;
		transition-duration: 300ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
	}
}

.checked {
	&::before {
		background-color: var(--checkbox-checked-background);
	}
}

.disabled {
	cursor: not-allowed;

	&::before,
	.icon {
		@include disabled_control();
		border: none;
	}

	&.checked::before {
		opacity: 0.3;
	}
}

.icon {
	position: absolute;
	color: #fff;
}

.text {
	padding: 0 0 0 25px;
    display: inline-block;
}

.additionalText {
	color: var(--neutral-color);
}
