$height: 20px;

.container {
	width: 42px;
	height: $height;

    cursor: pointer;
    display: inline-block;
    position: relative;
    user-select: none;
}

.track {
    background-color: var(--switch-track-unchecked-background);
	border: 1px solid var(--switch-track-border-color);
    border-radius: 20px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition: all .2s linear;
}

.thumb {
	background: var(--switch-thumb-unchecked-background);
	width: $height - 4px;
    height: $height - 4px;
	border-radius: 50%;
    position: absolute;
    top: 2px;
	left: 2px;
    bottom: 2px;
    transition: all .2s linear;
}

.active {
	.track {
		background-color: var(--switch-track-checked-background);
	}

	.thumb {
		margin-left: 22px;
		background: var(--switch-thumb-checked-background);
	}
}
