@import '../control.mixins.scss';

.container {
	display: flex;
	align-items: center;

	&.small {
		height: 100%;
	}

	.select_container {
		position: relative;
		flex: 1;
	}

	.description_container {
		margin: 0 0 0 8px;
	}

	.select {
		@include control();

		min-height: $control-height;
		height: auto;

		&.focus {
			@include focused_control();
		}

		&.disabled {
			@include disabled_control();
		}

		&.small {
			min-height: unset;
		}
	}
}

.arrow {
	position: absolute;
	right: 10px;
	top: 7px;
	pointer-events: none;

	&.small {
		top: 4px;
	}
}

.dropdown {
	@include dropdown();
	padding: 0;
	max-height: 256px; // 8 options * 32px
	overflow-y: auto;
	z-index: 130; // bigger than Dialog's 120

	&.not_portal {
		left: 0;
		right: 0;
	}

	input {
		background: transparent;
		border: none !important;
	}
}

.no_options {
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--field-dropdown-no-options-color);
	cursor: not-allowed;
}

.option {
	height: 32px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	color: var(--content-color);
	cursor: pointer;

	.option_text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&.focus,
	&:hover {
		background: var(--field-dropdown-active-background);
		color: var(--field-dropdown-active-color);
	}
}

.inline_block {
	display: inline-block;

	.link:hover {
		color: #007bff;
		text-decoration: underline;
	}
}
