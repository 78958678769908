.container {
	height: 32px;
    display: flex;
    align-items: center;
}

.multi_checkbox_item + .multi_checkbox_item {
	margin-left: 24px;
}

.container_column {
    display: flex;
	flex-direction: column;
}

.multi_checkbox_item_column {
	height: 26px;
}

.multi_checkbox_item_column + .multi_checkbox_item_column {
	margin-top: 10px;
}

// .multi_checkbox:disabled + .label {
// 	color: #a9a9a9;
// }
