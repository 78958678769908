body {
	--shell-icon-size: 20px;
	--menu-icon-size: 20px;
	--header-icon-size: 20px;

	// font-size
	--font-size-xlarge: 18px;
	--font-size-large: 15px;
	--font-size: 14px;
	--font-size-small: 12px;
	--font-size-button: var(--font-size);
	--font-size-table: var(--font-size);
	--font-size-control: var(--font-size);
}
