.line {
	position: absolute;
	top: 0;
	bottom: 0;

	width: 6px;
	cursor: ew-resize;

	&:hover,
	&.active {
		background-position: 3px;
		background-size: 1px 14px;
		background-repeat: repeat-y;
	}

	&:hover {
		background-image: linear-gradient(to bottom, var(--resize-indicator-hover-color) 40%, rgba(255, 255, 255, 0) 0%);
	}

	&.active {
		background-image: linear-gradient(to bottom, var(--resize-indicator-active-color) 40%, rgba(255, 255, 255, 0) 0%);
	}
}
