.container {
	position: relative;
}

.icon {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 8px;
	margin: auto 0;
}
