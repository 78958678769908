.vertical_xsmall {
	height: 4px;
}

.vertical_small {
	height: 8px;
}

.vertical_medium {
	height: 16px;
}

.vertical_large {
	height: 32px;
}

.vertical_xlarge {
	height: 48px;
}
