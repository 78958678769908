@import '../control.mixins.scss';

.container {
	display: flex;
	align-items: center;

	.select_container {
		position: relative;
		flex: 1;
	}

	.description_container {
		margin: 0 0 0 8px;
	}

	&.small {
		height: 100%;
	}
}

.arrow {
	position: absolute;
	right: 10px;
	top: 7px;
	pointer-events: none;

	&.small {
		top: 4px;
	}
}

.dropdown {
	@include dropdown();
	padding: 0;
	max-height: 160px; // 5 options * 32px
	overflow-y: auto;
	z-index: 130; // bigger than Dialog's 120

	&.not_portal {
		left: 0;
		right: 0;
	}
}

.no_options {
	height: 32px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--field-dropdown-no-options-color);
	cursor: not-allowed;
}

.option {
	height: 32px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	color: var(--content-color);
	cursor: pointer;

	&.selected,
	&:hover {
		background: var(--field-dropdown-active-background);
		color: var(--field-dropdown-active-color);
	}
}

.option_spinner {
	height: 30px
}
