@import '../Opener/colorPickerOpener.module.scss';

$arrow-height: 10px;

.color_picker_cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.color_picker_panel {
	position: absolute;
	top: $opener-size + $arrow-height;
	z-index: 1;
	width: 170px;
	background: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.1) 0 1px;
	// 7px because of color_picker_preview which is on top of this, and sometimes white pixels are visible in edges
	border-radius: 7px 7px 5px 5px;
}

.color_picker_panel_right {
	@extend .color_picker_panel;
	right: 0;
}

.color_picker_arrow {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 $arrow-height $arrow-height;
	position: absolute;
	top: -$arrow-height;
	left: calc($opener-size / 2);
	margin-left: -$arrow-height;
}

.color_picker_arrow_right {
	@extend .color_picker_arrow;
	left: unset;
	right: calc($opener-size / 2);
	margin-right: -$arrow-height;
}

.color_picker_preview {
	height: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 5px 5px 0 0;
}

.color_picker_default_colors_wrapper {
	padding: 10px;
}

$default_colors_margin: 10px;
$default_colors_color_margin: 6px;

.color_picker_default_colors_inner {
    display: flex;
	margin-right: -$default_colors_color_margin;
	margin-bottom: - $default_colors_color_margin;
}

.color_picker_default_colors_color {
	height: 25px;
	width: 25px;
	cursor: pointer;
	position: relative;
	outline: none;
	margin-right: $default_colors_color_margin;
	margin-bottom: $default_colors_color_margin;
	border-radius: 4px;
}

.color_picker_hue {
	position: relative;
}

.color_picker_hue_track {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 2px;
	background: linear-gradient(to right, #f00 0, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
}

.color_picker_hue_thumb {
	position: absolute;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.37) 0 0 2px 1px;
}
