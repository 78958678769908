.content {
	background-color: var(--table-tooltip-background);
	color: var(--content-color);
	font-size: var(--font-size-small);
	outline: 1px solid var(--table-separator-color);
	padding: 6px;
	border-radius: 4px;
	z-index: 10;
	max-width: 375px;
	word-wrap: break-word;
	white-space: normal;
}
