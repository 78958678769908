.container {
    margin-left: 6px;
}

.icon {
	vertical-align: middle;
    margin: 0px 10px 0 0;
}

.message {
	vertical-align: middle;
    word-break: break-word;
}

.message_with_route {
	@extend .message;

	&:hover {
		text-decoration: underline;
	}
}
