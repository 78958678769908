@import '../Opener/colorPickerOpener.module.scss';

$arrow-height: 10px;

.panel {
	position: absolute;
    top: 0;
    z-index: 1;
    background: var(--color-picker-simple-background);
    border: 1px solid var(--color-picker-simple-border-color);
    border-radius: 5px;
    left: 42px;
    padding: 6px;
}
