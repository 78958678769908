.btn {
	position: relative;
    display: inline-block;
	height: 32px;
    font-size: var(--font-size-button);
    padding: 7px 12px;
	margin: 0;
	border-radius: 2px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
	transition: all 0.3s ease-in-out, visibility 1ms; // added because of dashboardContainer form_inner { visibility: hidden; }, to hide buttons while dashboard form is sliding
	cursor: pointer;
	border-radius: 50rem;
	min-width: 64px;
}

.btn-primary {
	color: #fff;
	background-color: var(--primary-color);
	border-color: var(--primary-color);

	&:hover {
		&:not(:disabled) {
			background-color: #3274c4;
			border-color: #3274c4;
		}
	}
}

.btn-secondary {
	color: #fff;
	background-color: var(--secondary-color);
	border-color: var(--secondary-color);

	&:hover {
		&:not(:disabled) {
			background-color: #0c7e5a;
			border-color: #0c7e5a;
		}
	}
}

.btn-neutral {
	color: #fff;
	background-color: var(--neutral-color);
	border-color: var(--neutral-color);

	&:hover {
		&:not(:disabled) {
			background-color: #515151;
			border-color: #515151;
		}
	}
}

.btn-destructive {
	color: var(--content-color);
	background-color: transparent;
	border-color: var(--neutral-color);


	&:hover {
		&:not(:disabled) {
			color: white;
			background-color: #d7382ccc;
			border-color: #d7382ccc;
		}
	}
}

.btn.disabled, .btn:disabled {
	cursor: not-allowed;
	opacity: 0.55;
}
