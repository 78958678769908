.spinner {
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: center;

	> :not(:first-child) {
		margin-top: 10px;
	}
}

.text {
	text-align: center;
	line-height: 1.4;
}
