.container {
	display: flex;

	&.column_direction {
		flex-direction: column;
	}

	.row {
		display: flex;
		flex: 1;

		height: 32px;
		background: var(--tabulator-background);
		color: var(--table-header-color);
		border-bottom: 1px solid var(--table-header-separator-color);
		font-weight: 700;

		// added because we need to fill space between other cells and frozen right
		.cells_wrapper {
			display: flex;
			flex: 1;
		}
	}

}

.cell {
	display: flex;
    padding: 8px;
	position: relative; // sort icon is absolute
	user-select: none;

	&:is(.frozen) {
		background: var(--tabulator-background);
	}

	&.hover {
		.filter,
		.title {
			color: var(--table-header-active-color);
			cursor: pointer;
		}
	}

	.inner {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.inner_with_sort {
		padding-right: 10px; // because of sort icon and ellipsis
	}

	.inner_with_filter {
		padding-right: 28px; // because of filter icon, sort icon and ellipsis
	}
}

.sort {
	position: absolute;
	bottom: 6px;
	right: -6px;
	padding: 6px;
	margin-right: 6px;
	cursor: pointer;

	&::before, &::after {
		content: "";
		pointer-events: none;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		display: block;
		transition: border-color 0.3s ease, opacity 0.3s ease;
	}

	// top arrow
	&::before {
		border-bottom: 4px solid var(--table-header-color);
		margin-bottom: 2px;
	}

	// down arrow
	&::after {
		border-top: 4px solid var(--table-header-color);
	}

	&.ascending.active_sort::before {
		border-bottom-color: var(--table-active-color);
		margin-bottom: 4px;
	}

	// when ascending sort (hidden down arrow)
	&.ascending.active_sort::after {
		display: none;
	}

	&.descending.active_sort::after {
		border-top-color: var(--table-active-color);
		margin-bottom: 2px;
	}

	// when sorting descending (hidden top arrow)
	&.descending.active_sort::before {
		display: none;
	}

	&:hover {
		&::before, &.ascending.active_sort:hover::before {
			border-bottom-color: var(--table-header-active-color);
		}

		&::after, &.descending.active_sort:hover::after {
			border-top-color: var(--table-header-active-color);
		}
	}
}

.filter {
	position: absolute;
    bottom: 8px;
    right: 20px;
	cursor: pointer;
	color: var(--table-active-color);
}

.frozen {
	position: sticky;
	z-index: 1;
}
