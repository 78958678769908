.container {
	display: flex;
	flex-direction: column;
}

// added because .container flex-direction: column is forcing table to be wider than 100%
.row_wrapper {
	display: flex;
}

.row {
	display: flex;
	flex: 1;

	// da bi uracunao i border-bottom 1px i zapravo dodelio 33px visinu posto su celije 32px
	box-sizing: border-box;
	background: var(--tabulator-background);
	border-bottom: 1px solid var(--table-separator-color);

	// row and frozen cells (because of horizontal scrolling)
	&:hover,
	&:hover :is(.frozen) {
		background: var(--table-row-hover-background);
		color: var(--table-row-active-color);
	}

	// row and frozen cells (because of horizontal scrolling)
	&.selected,
	&.selected :is(.frozen) {
		background: var(--table-row-active-background);
		color: var(--table-row-active-color);
	}

	// added because we need to fill space between other cells and frozen right
	.cells_wrapper {
		display: flex;
		flex: 1;
	}
}

.cell {
	height: 32px; // when cell is empty, take height as row
	padding: 8px;
	cursor: pointer;
	position: relative;

	// for frozen cells not to be transparent when horizontal scrolling (row has background, not cell)
	&:is(.frozen) {
		background: var(--tabulator-background);
	}

	&.editing {
		overflow: hidden;
		padding: 4px;
	}

	.content {
		display: block;
		// shorten
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.hover_icon {
			display: none;
			margin-left: 6px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(-50%, -50%);
		}

		&.align_center {
			text-align: center;
		}

		&.align_right {
			text-align: right;
		}
	}

	&:hover {
		.hover_icon {
			display: block;
		}
	}

}

.semaphore {
	width: 10px;
	height: 10px;
	margin: 3px 0;
	border-radius: 50%;
}

.reference_link {
    display: flex;
	align-items: center;

	.icon {
		margin-right: 6px;
	}
}

.frozen {
	position: sticky;
	z-index: 1;
}

.row_toggler {
	user-select: none;

	&.row_group {
		display: inline-flex;
		align-items: center;
		flex-wrap: nowrap;

		span {
			padding-left: 6px;
			white-space: nowrap;
		}

		.count, .level {
			color: var(--neutral-color);
		}

		.diagram, .level {
			padding-left: 12px;
		}

		.diagram:hover {
			color: var(--secondary-color);
		}
	}

	&.level_0 {
		padding-left: 0px;
	}
	&.level_1 {
		padding-left: 20px;
	}
	&.level_2 {
		padding-left: 40px;
	}
	&.level_3 {
		padding-left: 60px;
	}
	&.level_4 {
		padding-left: 80px;
	}
}

.toggler_collapsed {
	transform: rotate(180deg);
	transition: all 200ms ease;
}

.toggler_expanded {
	transform: rotate(270deg);
	transition: all 200ms ease;
}
