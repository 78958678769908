.column {
	transition: background-color 0.2s ease;
	user-select: none;
	min-height: 80px; //2x height of the element

	&.dragging_over>div {
		background-color: var(--dragndrop-over-active-color);
	}

	&.dragging_over {
		background-color: var(--dragndrop-border-color);
		border-radius: 8px;
		padding: 8px;
	}
}

.draggable_element {
	padding: 8px;
	border: 1px solid var(--dragndrop-border-color);
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: 'unset';
	transition: background-color 0.25s ease;

	&:hover {
		background-color: var(--dragndrop-hover-background);
	}

	&.dragging {
		background-color: var(--dragndrop-active-color);
	}
}

.frozen_element {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
	border: 1px solid var(--dragndrop-border-color);
	border-radius: 2px;
	margin-bottom: 8px;
	user-select: none;
	cursor: not-allowed;

	.icon {
		margin-left: 6px;
	}
}
