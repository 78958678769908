@import '../control.mixins.scss';

.container {
	display: flex;
}

.item {
	@include control();
	position: relative; // because of spinner
	display: flex;
	align-items: center;
	width: unset;
	border-radius: 16px;
	padding: 7px 12px;
	cursor: pointer;
}

.icon {
	margin-left: 12px;
	cursor: pointer;

	&:hover {
		color: var(--error-color);
	}
}

.disabled {
	@extend .item;
	background: var(--field-disabled-background);
	color: var(--field-disabled-color);
	// cursor: not-allowed;
}

.link {
	border: 1px solid var(--brand-color);
}

// .download {

// }
