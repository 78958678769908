// this project uses normalize.css https://github.com/necolas/normalize.css/
// this file is addition to normalize

@import url("https://fonts.googleapis.com/css?family=Mada:400,500,600");
@import './layout.variables.scss';

body {
	--brand-color: #3D8EEF;
}

*, :after, :before {
    box-sizing: border-box;
}

:focus-visible {
	outline: none; // chrome has outline: -webkit-focus-ring-color auto 1px;
}

html, body {
	height: 100%;
	overflow: hidden;
}

body {
    font-family: "Mada",sans-serif;
	font-size: var(--font-size);
	font-weight: 400;
	line-height: unset;
	color: var(--content-color);
    text-align: left;
}

ol, ul {
	margin: 0;
	// list-style: none;
	margin-block-start: 0;
	margin-block-end: 0;
    // padding-inline-start: 0;
}

a {
	color: unset;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
	line-height: 1.2;
}

// only for content header
h1 {
	font-size: 20px;
	font-weight: bold;
	color: var(--h1-color);
}

// modal dialog, Project Report components, onboarding pages
h2 {
	font-size: 18px;
	font-weight: bold;
	color: var(--h2-color);
	margin-bottom: 28px;
}

// help title, active "Filter:"
h3 {
	font-size: 16px;
	font-weight: bold;
	color: var(--h3-color);
	margin-bottom: 24px;
}

// Project Report sub-components
h4 {
	font-size: 16px;
	font-weight: bold;
	color: var(--h4-color);
	margin-bottom: 20px;
}

// all inline titles like Form Group title
h5 {
    font-size: 14px;
	font-weight: 500;
	color: var(--h5-color);
	margin-bottom: 16px;
}

hr {
    border: 0;
}

img {
    vertical-align: middle;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

p {
	margin: 0;
}

hr {
	margin: 10px 0;
	border-top: 1px solid var(--content-separator-background)
}
