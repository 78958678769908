.container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background: rgba($color: #000, $alpha: 0.55);
}

.transparent {
	background: unset;
}
