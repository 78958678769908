@import '../control.mixins.scss';

.container {
	height: 32px;
    display: flex;
    align-items: center;

	.item + .item {
		margin-left: 24px;
	}
}

.container_column {
    display: flex;
	flex-direction: column;

	.item {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 26px;

		& + .item {
			margin-top: 10px;
		}
	}
}

.item {
	cursor: pointer;
}

.circle {
	width: 16px;
	height: 16px;
	background: var(--field-background);
	border-radius: 50%;
	box-sizing: border-box;
	padding: 3px;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid var(--field-border-color);

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		background-color: var(--radio-unchecked-background);
		border-radius: 50%;
		transition: background-color 300ms ease-in-out;
	}
}

.checked {
	.circle {
		background: transparent;
		border: 1px solid var(--radio-checked-background);

		&::after {
			background-color: var(--radio-checked-background);
		}
	}
}

.disabled {
	cursor: not-allowed;

	.circle {
		@include disabled_control();
		border: none;
	}

	&.checked .circle::after {
		opacity: 0.3;
	}
}

.text {
	padding-left: 8px;
	vertical-align: middle;
}
