@import '../control.mixins.scss';

input:-webkit-autofill {
  border: 1px solid transparent;
  -webkit-text-fill-color: white;
  box-shadow: 0 0 0px 1000px #1A1A1A inset;
  transition: background-color 5000s ease-in-out 0s;
}

.wrapper {
	display: flex;
	align-items: center;

	&.small {
		height: 100%;
	}
}

.container {
	flex: 1;
	position: relative;
}

.input {
	@include control();

	&:focus ~ .max_length {
		display: block;
	}

	&.transparent_cursor {
		caret-color: transparent;
	}
}

.max_length {
	display: none;
}

.explanation {
	@include explanation();
}
