.theme {
	// brand
	--primary-color: var(--brand-color);
	--secondary-color: #0F996D;
	--neutral-color: #aaaaaa;
	--destructive-color: rgba(244, 67, 54, 0.8);

	--error-color: #FF245A;

	// dom
	--h1-color: #4d4d4f;
	--h2-color: #4d4d4f;
	--h3-color: #686868;
	--h4-color: #686868;
	--h5-color: #686868;
	--h6-color: #686868;
	--scroll-bar-background: #cccfd4;

	// shell

	--app-header-background: #fff;
	--app-header-color: #4d4d4f;
	--app-header-active-color: var(--brand-color);
	--app-header-bottom-separator-background: #eff3f6;

	--user-panel-background: #fff;
	--user-panel-color: #4d4d4f;
	--user-panel-item-active-background: #ddd;
	--user-panel-item-active-color: #000;
	--user-panel-item-separator-background: #eff3f6;

	--menu-background: #eff3f6;
	--menu-color: #4d4d4f;
	--submenu-background: #e1e3e5;
	--submenu-color: #727272;
	--submenu-selected-color: var(--brand-color);
	--submenu-hover-color: #1a1a1a;

	--content-background: #ffffff;
	--content-color: #4d4d4f;
	--content-title-color: var(--brand-color);
	--content-subtitle-color: var(--brand-color);
	--content-separator-background: #eff3f6;
	--content-selected-color: var(--brand-color);

	--help-background: #fff;
	--help-color: #4d4d4f;
	--help-box-shadow: #ccc 0px 10px 10px 0px;
	--help-title-color:  var(--brand-color);
	--help-subtitle-color: #D6D6D6;
	--help-toggler-background: var(--brand-color);
	--help-toggler-color: #fff;

	--with-fetch-background: var(--content-background)80;


	// components

	--field-background: #fff;
	--field-color: #4d4d4f;
	--field-border-color: rgba(77,77,79,0.2);
	--field-disabled-background: rgba(77, 77, 79, .06);
	--field-disabled-color: rgba(77, 77, 79, .45);
	--field-placeholder-color: #21252980;
	--field-focus-border-color: #80bdff;
	--field-max-length-background: #fff;
	--field-max-length-color: #80bdff;
	--field-error: var(--error-color);
	--field-warning: #F5C050;
	--checkbox-checked-background: var(--brand-color);
	--checkbox-unchecked-background: transparent;
	--radio-checked-background: var(--brand-color);
	--radio-unchecked-background: transparent;
	--switch-track-border-color: var(--field-border-color);
	--switch-track-checked-background: var(--field-background);
	--switch-track-unchecked-background: var(--field-background);
	--switch-thumb-checked-background: var(--brand-color);
	--switch-thumb-unchecked-background: var(--content-color);
	--field-dropdown-background: #fff;
	--textarea-icon-formating-color: #21252980;
	// dropdown
	--field-dropdown-background: #fff;
	--field-dropdown-active-background: var(--content-selected-color);
	--field-dropdown-active-color: #fff;
	--field-dropdown-no-options-color: #9b9b9b;

	--header-separator-color: #ccccccbf;

	--table-header-color: #9b9b9b;
	--table-header-active-color: #1a1a1a;
	--table-header-separator-color: var(--header-separator-color);
	--table-row-active-background: var(--content-selected-color);
	--table-row-hover-background: #ddd;
	--table-row-active-color: #fff;
	--table-separator-color: #e0e0e0c9;
	--table-active-color: var(--content-selected-color);
	--table-frozen-separator-color: var(--table-separator-color);
	--table-tooltip-background: #fff;
	--paging-disabled-color: #21252980;
	--table-active-filter-background: #47474733;
	--skeleton-cell-background: #43434340;
	--resize-indicator-active-color: var(--table-header-color);
	--resize-indicator-hover-color: #4e4e4e40;

	--tabulator-background: var(--content-background);

	--dragndrop-border-color: #e0e0e0;
	--dragndrop-active-color: var(--brand-color);
	--dragndrop-over-active-color: var(--brand-color)aa;
	--dragndrop-hover-background: #f3f3f3;

	--popup-background: #fff;
	--popup-color: #4d4d4f;
	--popup-border-color: var(--content-separator-background);

	--color-picker-simple-background: #fff;
    --color-picker-simple-border-color: #ddd;

	--tab-color: #686868;
	--tab-active-color: #1a1a1a;
	--tab-active-background: var(--brand-color);
	--tab-separator-color: var(--header-separator-color);

	--widget-background: #fafafa;
	--widget-separator:#eeeeee;
	--legend-inactive: #999999;

	--note-backgrounnd: #efefef;

	--map-item-background: rgba(77, 77, 79, .06);
	--map-item-add-background: transparent;
	--map-item-add-border-color: var(--field-border-color);
	--map-item-selected-color: #fff;
	--map-item-selected-background: var(--content-selected-color);
	--map-line-background: var(--field-border-color);

	// button group

	--primary-hover-color: #fff;
	--primary-unselected-background: #e9e9e9;
	--primary-selected-background: var(--primary-color);
	--primary-border-color: #fff;
	--primary-hover-background: #3274c4;

	--neutral-hover-color: #fff;
	--neutral-unselected-background: #e9e9e9;
	--neutral-selected-background: var(--neutral-color);
	--neutral-border-color: #fff;
	--neutral-hover-background: #898989;

}
