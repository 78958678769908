.cell {
	padding: 5px;
	font-size: var(--font-size-small);
	border: 1px solid var(--table-separator-color);
}

.hr {
	margin: 5px 0;
}

.column_header {
	@extend .cell;
	vertical-align: top;
	color: var(--table-header-color);
}

.row_header {
	@extend .cell;
	vertical-align: middle;
	color: var(--table-header-color);
}
