.container {
	margin: 0;
	padding-top: 1px;
	font-size: var(--font-size-small);
}

.error {
	color: var(--field-error);
}

// .info {
// }

.warning {
	color: var(--field-warning);
}
