.skeleton {
	width: 100%;
	position: relative; // because of OverlaySpinner
}

.header, .row {
	display: flex;
	height: 32px;
}

.header {
	border-bottom: 1px solid var(--table-header-separator-color);
}

.cell {
	margin: 8px;
	border-radius: 8px;
	background-color: var(--skeleton-cell-background);

	&.flex_1 {
		flex: 1;
	}

	&.flex_2 {
		flex: 2;
	}

	&.flex_3 {
		flex: 3;
	}
}
