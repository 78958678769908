@import '../control.mixins.scss';

:global {
	.react-datepicker-wrapper {
		display: block !important;
	}

	.react-datepicker__header__dropdown {
		line-height: 21px;
	}

	.react-datepicker-popper {
		z-index: 130; // bigger than Dialog's 120
	}
}

.container {
	position: relative;
}

.date_picker {
	@include control();
}

.icon {
	position: absolute;
	top: 7px;
    left: calc(100% - 16px - 10px); // 16px is width of icon, 10px is right padding of input
	pointer-events: none;

	&.small {
		top: 4px;
	}
}
