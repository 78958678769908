// arrow is half of square, so size is basically half of that square diagonal
// INFO: if changing arrow_size, minSize in js should be changed also.
$arrow_size: 10px;
// side is side of a square (1.41 = Math.sqrt(2), this formula is from Pythagoras theorem, when two sides are same)
$arrow_side: $arrow_size * 1.41;

.wrapper {
	position: relative;
	width: fit-content;
	display: inline-block;
}

// popup container

.popup {
	transform: scale(0);
	position: absolute;
	transition: all .1s linear;
	z-index: 1;
}

.popup_top {
	top: -$arrow_size;
}

.popup_bottom {
	bottom: -$arrow_size;
}

.popup_left {
	left: -$arrow_size;
}

.popup_right {
	right: -$arrow_size;
}

// inner

.popup_inner {
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	border: 1px solid var(--popup-border-color);
	width: 100%;
	height: 100%;
	background: var(--popup-background);
	color: var(--popup-color);
	padding: 16px;
}

// arrow

.popup_arrow {
	width: $arrow_side;
	height: $arrow_side;
	background: linear-gradient(45deg, var(--popup-background) 55%, transparent 50%);
	position: absolute;
	margin: calc(-#{$arrow_side} / 2);
	border-style: solid;
	border-width: 0 0 1px 1px;
	border-color: transparent transparent var(--popup-border-color) var(--popup-border-color);
}

.popup_arrow_top {
	bottom: 0;
	transform: rotate(-45deg);
}

.popup_arrow_bottom {
	top: 0;
	transform: rotate(135deg);
}

.popup_arrow_left {
	right: 0;
	transform: rotate(-135deg);
}

.popup_arrow_right {
	left: 0;
	transform: rotate(45deg);
}

// close icon

.popup_close {
	position: absolute;
	top: 3px;
	right: 3px;
}
