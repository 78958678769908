@import '../mixins.scss';

.spinner_clip {
	@include border_spinner();
	animation-fill-mode: both;
	animation: spinner_clip 0.75s 0s infinite linear;
}

@keyframes spinner_clip {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(0.8);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}
