@import '../control.mixins.scss';

.wrapper_editor {
	@include control();
	height: unset;

	&.disabled {
		@include disabled_control();
	}
}

.toolbar {
	background-color: transparent;
	border: none;
	padding: 0px;
	margin-bottom: 0;
}

.editor {
	position: relative; /* added position and z-index because text is over the sidebar and select dropdown */
	z-index: 0;

	&.disabled {
		border-top: none;
	}
}

:global {
	.rdw-editor-wrapper {
		box-sizing: border-box;
	}

	.rdw-option-wrapper {
		background: transparent;
		color: var(--textarea-icon-formating-color);

		height: 22px;
		padding: 5px;
		margin-top: 4px;
		border: none;
		box-shadow: none;

		&:hover,
		&[aria-selected='true'] {
			box-shadow: none;
			color: var(--content-color);
		}

		&:first-child {
			margin-left: 0;
		}
	}

	.rdw-link-modal,
	.rdw-image-modal {
		background: var(--content-background);
		color: var(--content-color);
		border: 1px solid var(--field-dropdown-active-background);
		border-radius: 4px;
		box-shadow: none;
		height: unset;

		.rdw-link-modal-label,
		.rdw-link-modal-target-option {
			font-size: var(--font-size);
		}

		.rdw-link-modal-input,
		.rdw-image-modal-url-input,
		.rdw-image-modal-size-input {
			@include control();
		}

		.rdw-link-modal-buttonsection,
		.rdw-image-modal-btn-section {
			display: flex;
			justify-content: space-evenly;
			margin: 0;

			.rdw-link-modal-btn,
			.rdw-image-modal-btn {
				background-color: var(--primary-color);
				border-color: var(--primary-color);
				color: #fff;
				min-width: 64px;
				height: 32px;
				padding: 7px 12px;
				margin: 0;
				border-radius: 2px;
				font-size: var(--font-size-button);
				text-align: center;
				vertical-align: middle;
				border: 1px solid transparent;
				border-radius: 50rem;
				transition: all 0.3s ease-in-out;
				cursor: pointer;

				&:disabled {
					cursor: not-allowed;
					opacity: 0.55;
				}

				&:hover {
					background-color: #3274c4;
					border-color: #3274c4;
					box-shadow: none;
				}

				&:last-child {
					background-color: transparent;
					border-color: var(--neutral-color);
					color: var(--content-color);

					&:hover {
						color: white;
						background-color: #d7382ccc;
						border-color: #d7382ccc;
					}
				}
			}

		}

		.rdw-image-modal-header-label {
			background: var(--neutral-color);

			&.rdw-image-modal-header-label-highlighted {
				border: 1px solid var(--primary-color);
			}
		}

		.rdw-image-modal-upload-option {
			background-color: var(--content-background);
			color: var(--content-color);
			font-size: var(--font-size);
			outline: 2px dashed var(--field-dropdown-active-background);
			margin: 0;
			padding: 0;
		}

		.rdw-image-modal-size {
			margin-bottom: 20px;
		}

		.rdw-image-mandatory-sign {
			color: var(--content-color);
		}

	}

	.rdw-image-imagewrapper .rdw-image-alignment-options-popup {
		background: var(--content-background);
		border: 1px solid var(--field-dropdown-active-background);
		display: flex;
		justify-content: space-evenly;
	}

	.rdw-link-decorator-wrapper {
		color: var(--primary-color);

		&:hover {
			text-decoration: underline;
		}

		.rdw-link-decorator-icon {
			background-color: var(--field-background);
		}
	}

	.rdw-emoji-modal {
		background: var(--content-background);
		color: var(--content-color);
		border: 1px solid var(--field-dropdown-active-background);
		border-radius: 4px;
		box-shadow: none;
		min-height: 180px;
	}
}
